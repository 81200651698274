let api_urls = {
  client_id: '2',
  client_secret: 'dhGLOnQlLZhDPJmRaNXXJcAOEcDZnqMtm7VGpc5t',
  base_url: 'https://api.staging.iclinicpay.com.br/',
  base_api: 'https://api.staging.iclinicpay.com.br/api/v1/',
  base_api_v2: 'https://api.staging.iclinicpay.com.br/api/v2/',
  receipt: 'https://receipt.staging.iclinicpay.com.br/',
};

export const environment = {
  name: 'staging',
  production: false,
  title: 'Staging',
  zoop_gateway_id: '90ba19c4-e347-4126-b797-74c3b5626622',
  api: api_urls,
  cookieConsent: 'https://cookie-consent.staging.iclinicpay.com.br/cookieConsent.js',
  snowplow_collector_url: '',
  snowplow_app_id: 'medicinae_frontend_organizacao',
  intercom_app_id: 'gl163exs',
  minimum_installment_value: 5,
  featureFlags: {
    modalityScreenAvaliable: false,
    maintenanceAlert: false,
    maintenancePage: false,
  },
};
